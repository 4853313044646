import React, { useState } from "react"
import { graphql } from "gatsby"
import "../../theme/type.scss"
import CustomFooter from "../../components/CustomFooter"
import GdprCookies from "../../components/GdprCookies"
import HeaderLayout from "../../components/layout/HeaderLayout"
import "../../theme/layout.scss"
import "../../theme/david.scss"


import ModalSuscribe from "../../components/layout/ModalSuscribre"


const Comentario = ({ data }) => {
  //functions modal suscribe
  const [open, setOpen] = useState(false)

  function openSuscribe() {
    setOpen(true)
  }

  function closeSuscribe() {
    setOpen(false)
  }

  console.log(data)

  return (
    <>
      <HeaderLayout mb={true} suscribe={true} openModal={openSuscribe} />
      <div className="wrap-content">
        <div className="header--section">
          <h2 className="title--section">CALENDARIO DE PAGO DE DIVIDENDOS</h2>
        </div>

        <div>
          {/*<table className={"table-bordered table-striped"}>*/}
          {/*  <thead className={"bg-head"}>*/}
          {/*  <tr>*/}
          {/*    <th>Emisora</th>*/}
          {/*    <th>Serie</th>*/}
          {/*    <th>Tipo de Derecho</th>*/}
          {/*    <th>Concepto</th>*/}
          {/*    <th>Fecha Excupón</th>*/}
          {/*    <th>Fecha Pago</th>*/}
          {/*    <th>Exhibición</th>*/}
          {/*    <th>Yield</th>*/}
          {/*  </tr>*/}
          {/*  </thead>*/}
          {/*  <tbody>*/}
          {/*  {data.allAirtable.edges.map((item, index) => (*/}
          {/*    <tr key={index}>*/}
          {/*      <td>{item.node.data.Emisora}</td>*/}
          {/*      <td>{item.node.data.Serie}</td>*/}
          {/*      <td>{item.node.data.Tipo_de_Derecho}</td>*/}
          {/*      <td>{item.node.data.Concepto}</td>*/}
          {/*      <td>{item.node.data.Fecha_Excup_n}</td>*/}
          {/*      <td>{item.node.data.Fecha_Pago}</td>*/}
          {/*      <td>{item.node.data.Exhibici_n}</td>*/}
          {/*      <td>{item.node.data.Yield}</td>*/}
          {/*    </tr>*/}
          {/*  ))}*/}
          {/*  </tbody>*/}
          {/*</table>*/}
          <iframe className="airtable-embed"
                  src="https://airtable.com/embed/appTIIVoNxZwESogt/shryAvRBal1VxEyOg?viewControls=on"
                  width="100%" height="533" style={{ background: "transparent", border: "1px solid #ccc" }}>

          </iframe>

        </div>
      </div>
      <CustomFooter openModal={openSuscribe} />
      <GdprCookies />
      {
        open && <ModalSuscribe closeModal={closeSuscribe} />
      }
    </>
  )
}

// # allAirtable {
// #   edges {
//   #     node {
//     #       data {
//       #         Emisora
//       #         Serie
//       #         Tipo_de_Derecho
//       #         Concepto
//       #         Fecha_Excup_n
//       #         Fecha_Pago
//       #         Exhibici_n
//       #         Yield
//       #       }
//     #     }
//   #   }
// # }
// allAirtable(filter: {table: {eq: "segunda"}}) {
//   edges {
//     node {
//       data {
//         Emisora,
//           Serie
//       }
//     }
//   }
// }
// export const query = graphql`
//   {
//     allAirtable {
//     edges {
//       node {
//        data{
//         Emisora,
//         Serie,
//         Tipo_de_Derecho,
//         Concepto,
//         Fecha_Excup_n,
//         Fecha_Pago,
//         Exhibici_n,
//         Yield
//       }
//
//       }
//     }
//   }
//   }
// `
export default Comentario
